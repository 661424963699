import React from "react";
import { useSelector } from "react-redux";
import UpdateUserComponent from "../../views/Settings/userSettings/userSettings";
import './_style.scss'
const UpdateuserComponent = (props) => {
  const { handleClose, id, data } = props;
  const { updateUserStatus, updateUserError } = useSelector(
    (state) => state?.user
  );
  return (
    <>
      <UpdateUserComponent
        handleClose={handleClose}
        id={id}
        onUser={data}
        updateUserTest={true}
        updateUserStatus={updateUserStatus}
        updateUserError={updateUserError}
      />
    </>
  );
};

export default UpdateuserComponent;
