import React, { useEffect, useState } from "react";
import Card from "../../components/Card/Card";
import EmployeeDetails from "../../components/EmployeeDetails/userDatails/index";
import LeaveBalanceItem from "../../components/EmployeeDetails/LeaveBalance/LeaveBalanceItem";
import SubTitle from "../../components/SubTitle/SubTitle";
import "../Employees/_employee.scss";
import { fetchMe, fetchONeUser } from "../../slices/user";
import { getAllLeaves } from "../../slices/leave";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/Table/index";
import TabPanel from "../../components/TabPanel";

import {
  LeavesHeader,
  LeavesAllowedList,
  LeavesSortBy,
  tabsOptionsUserDetails,
  AuthorisationsHeader,
  AuthorisationsSortBy,
  AuthorisationsAllowedList,
} from "../../utilities/constants";
import LoadingScreen from "../../components/LoadingScreen";
import Notice from "../../components/Notice";
import getAccess from "../../utilities/getAccess";
import { getAllAuthorisations } from "../../slices/authorisation";
const Employee = (props) => {
  const dispatch = useDispatch();

  const [notice, setNotice] = useState({
    open: false,
    text: "",
    state: "",
  });
  const { handleClose, id, id_user } = props;
  const oneUser = useSelector((state) => state?.user?.oneUser?.user);

  const { oneuserStatus } = useSelector((state) => state?.user);
  const user = useSelector((state) => state?.user?.fetchMe);
  useEffect(() => {
    if (user?.length === 0) dispatch(fetchMe());
  }, []);

  let { AllLeaves, status } = useSelector((state) => state?.leave);
  let { allAuthorisations } = useSelector((state) => state?.authorisation);
  AllLeaves = AllLeaves?.filter(
    (leaveStatus) =>
      leaveStatus?.status?.code === "approved" &&
      leaveStatus?.user?._id === oneUser?._id
  );
  allAuthorisations = allAuthorisations?.filter(
    (leaveStatus) =>
      leaveStatus?.status?.code === "approved" &&
      leaveStatus?.user?._id === oneUser?._id
  );
  const [value, setValue] = useState(0);
  const [lastLeaveRequest, setlastLeaveRequest] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (value === 0) {
      setlastLeaveRequest(false);
    } else {
      setlastLeaveRequest(true);
    }
  };

  useEffect(() => {
    if (
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "USER",
        "VIEW"
      )
    ) {
      dispatch(fetchONeUser(id_user));
    }
    if (
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "LEAVE",
        "LIST"
      )
    ) {
      dispatch(getAllLeaves());
    }
    if (
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "AUTHORISATION",
        "LIST"
      )
    ) {
      dispatch(getAllAuthorisations());
    }
  }, [user]);

  return (
    <>
      <Notice open={notice.open} text={notice.text} state={notice.state} />

      {oneuserStatus === "succeeded" ? (
        <div className="employee_details__container">
          <div className="employee_details__container__info">
            <SubTitle subTitle={"Employee Details"} />
            <Card>
              <EmployeeDetails
                handleClose={handleClose}
                id={id}
                oneUser={oneUser}
                id_user={id_user}
                setNotice={setNotice}
              />
            </Card>
          </div>

          <div className="employee_details__container__stat">
            <SubTitle subTitle={"Leave Balance"} />
            <LeaveBalanceItem AllLeaves={AllLeaves} />
            <SubTitle subTitle={"Last approved request "} />
            <Card>
              <TabPanel
                options={tabsOptionsUserDetails}
                handleChange={handleChange}
                value={value}
                children={
                  <Table
                    noDataClass={"no-data-container"}
                    header={
                      lastLeaveRequest
                        ? LeavesHeader.slice(0, -1)
                        : AuthorisationsHeader.slice(0, -1)
                    }
                    rows={
                      lastLeaveRequest
                        ? AllLeaves?.reverse()
                        : allAuthorisations?.reverse()
                    }
                    isCollapsed={false}
                    tableName={lastLeaveRequest ? "leaves" : "authorisations"}
                    isAction={false}
                    noAction={false}
                    isNegotiation={false}
                    isPaging={true}
                    allowed={
                      lastLeaveRequest
                        ? LeavesAllowedList
                        : AuthorisationsAllowedList
                    }
                    sortBy={
                      lastLeaveRequest ? LeavesSortBy : AuthorisationsSortBy
                    }
                  />
                }
              />
            </Card>
          </div>
        </div>
      ) : (
        <div className="employee_details__container">
          <LoadingScreen />
        </div>
      )}
    </>
  );
};

export default Employee;
