import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import EmployeeDetails from "./index.jsx";
import "./_style.scss";
import { useSelector } from "react-redux";
import LoadingScreen from "../../components/LoadingScreen";

const UpdateUserMondal = ({
  title,
  className,
  id,
  open,
  handleClose,
  data,
  isEmployee,
  ...rest
}) => {
  const { oneuserStatus } = useSelector((state) => state?.user);

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason == "backdropClick" && reason !== "escapeKeyDown") {
            handleClose(id);
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-modal"
      >
        <DialogTitle id="alert-dialog-title" className="modal_header">
          <span className={className}> {title} </span>
          <div className="close_button">
            <CloseIcon onClick={() => handleClose(id)} />
          </div>
        </DialogTitle>
        <DialogContent>
          <EmployeeDetails
            id={id}
            handleClose={handleClose}
            id_user={data?.id}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateUserMondal;
